<template>
    <!-- <Intro /> -->
    
    <AlertBundle />
    <ToTop />

    <Header />
    
    <router-view/>

    <Footer />

</template>

<script>
// import Intro from '@/components/Intro'

import AlertBundle from '@/components/AlertBundle'
import ToTop from '@/components/ToTop'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
    components: {
        // Intro,
        AlertBundle,
        ToTop,
        Header,
        Footer,
    }
}
</script>

<style>
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");
</style>