

function init(el){
    el.classList.remove('imageMotion_on')
    el.classList.add('imageMotion_yet')
}

const textMotion = {
    beforeMount(el) {
        init(el);
    },
    mounted(el, binding) {
        function motion() {
            el.classList.remove('imageMotion_yet')
            el.classList.add('imageMotion_on')
        }

        function createObserver() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) { // 감지대상이 교차영역에 진입 할 경우
                        motion();
                        // observer.unobserve(el); // 이미지 로딩 이후론 관찰할 필요 x
                    } else {
                        init(el);
                    }
                });
            }, {
                rootMargin: binding.value && binding.value.rootMargin ? binding.value.rootMargin : '0% 0px -10%',
                threshold: binding.value && binding.value.threshold ? binding.value.threshold : 0,
            });

            observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 모션을 시켜도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : motion();
    }
};

export default textMotion;
