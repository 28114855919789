<template>
    <div class="c_screen_filter" v-if="this.alertContent || this.confirmContent || this.errorContent"></div>
    <transition name="bundle-popup">
        <div class="alert_bundle alert" v-if="this.alertContent">
            <div class="content">
                <p>{{this.alertContent}}</p>
                <button class="btn btn_close" id="alert_close_btn" @click="closeAlert">확인</button>
            </div>
        </div>
    </transition>

    <transition name="bundle-popup">
        <div class="alert_bundle confirm" v-if="this.confirmContent">
            <div class="content">
                <p>{{this.confirmContent}}</p>
                <button class="btn btn_continue" @click="confirmContinue">확인</button>
                <button class="btn btn_close" id="confirm_close_btn" @click="confirmCancel">취소</button>
            </div>
        </div>
    </transition>

    <transition name="bundle-popup">
        <div class="alert_bundle error" v-if="this.errorContent">
            <div class="content">
                <p>{{this.errorContent}}</p>
                <button class="btn btn_close" id="error_close_btn" @click="closeError">확인</button>
            </div>
        </div>
    </transition>
    
</template>

<script>
import { mapState } from "vuex";

export default{
    name : 'AlertBundle',
    computed:{
        ...mapState( ['alertContent', 'confirmContent', 'errorContent'] ),
    },
    updated(){
        if(this.alertContent){
            document.getElementById('alert_close_btn').focus();
        } else if(this.confirmContent){
            document.getElementById('confirm_close_btn').focus();
        } else if(this.errorContent) {
            document.getElementById('error_close_btn').focus();
        }
    },
    methods : {
        closeAlert(){
            this.$store.commit('closeAlert');
        },
        confirmContinue(){
            this.$store.commit('confirmContinue');
        },
        confirmCancel(){
            this.$store.commit('confirmCancel');
        },
        closeError(){
            this.$store.commit('closeError');
        }
    },
}
</script>

<style scoped>
/* alert_bundle 공통 시작 */
.c_screen_filter{
    z-index: 1000;
}
.alert_bundle{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40rem;
    min-width: calc(var(--min-width) * 0.9);
    width: 90%;
    background-color: #fff;
    border-radius: .8rem;
    z-index: 1000;
}
.alert_bundle .content{
    height: calc(100% - 10rem);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    font-size: 2rem;
    word-break: keep-all;
}
.alert_bundle .content p{
    font-weight: var(--font-size-mid);
}
.alert_bundle .content .btn{
    display: inline-block;
    border: none;
    font-size: 1.6rem;
    color: #fff;
    padding: .9rem 2.6rem;
    border-radius: var(--border-radius-small);  /* 원래 .8rem */
    margin-top: 3rem;
    font-weight: var(--font-size-mid);
    cursor: pointer;
}
/* alert_bundle 공통 끝 */

/* alert */
.alert .content .btn_close{
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
}
.alert .content .btn_close:hover{
    /* filter: brightness(0.93); */
    background-color: var(--color-primary-effect);
}

/* confirm */
.confirm .content .btn_continue{
    background-color: var(--color-bg-gray);
    margin-right: 10px;
    color: #000;
}
.confirm .content .btn_continue:hover{
    filter: brightness(0.93);
}
.confirm .content .btn_close{
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
}
.confirm .content .btn_close:hover{
    /* filter: brightness(0.93); */
    background-color: var(--color-primary-effect);
}
/* error */
.error{
    border: 1px solid var(--color-danger);
}
.error .content .btn_close{
    background-color: var(--color-danger);
}
.error .content .btn_close:hover{
    filter: brightness(1.1);
}

/* media query */
@media screen and (max-width: 40rem) {
    .alert_bundle .content {
        font-size: 1.8rem;
    }
}

/* transition bundle-popup (common으로 옮기면 동작X) */
.bundle-popup-enter-from,
.bundle-popup-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
}
.bundle-popup-enter-active,
.bundle-popup-leave-active {
    transition: all .1s ease-in-out;
}
</style>