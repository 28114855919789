<template>
    <div class="c_content_container home">
        <!-- slogun START -->
        <div class="slogun">
            <div class="parallax">
                <h1 v-textMotion>
                    <span class="textMotion_target">
                        START <br/>
                        <span>Business</span>
                        &amp;
                        <span>Management</span>
                    </span>
                </h1>
            </div>
        </div>
        <!-- slogun END -->
        <!-- 비디오 START -->
        <section class="c_section moive">
            <div class="display">
                <video v-if="this.windowSize > 1100" autoplay="autoplay" muted="muted" loop="loop" playsinline poster="/openGraph.jpg">
                    <source src="@/assets/movie/main_mov_v2.mp4" type="video/mp4">
                    <img src="@/assets/movie/main_mobile.gif" alt="main_video"/>
                </video>
                <video v-else autoplay muted loop playsinline>
                    <source src="@/assets/movie/main_mov_v2_small.mp4" type="video/mp4">
                    <img src="@/assets/movie/main_mobile.gif" alt="main_video"/>
                </video>
                <!-- <img v-else src="@/assets/movie/main_mobile.gif" alt="main_video"/> -->
            </div>
        </section>
        <!-- 비디오 END -->

        <!-- 개요 START -->
        <section class="c_section overview">
            <div class="c_inner">
                <div class="textbox">
                    <img v-fadein:reverse="{time:2}" src="@/assets/images/main/logo_skyblue.svg" alt="bg" />
                    <h2 v-textMotion><span class="textMotion_target"><span class="text_deco">위아오너</span>와 함께라면 창업이 쉬워집니다.</span></h2>
                    <br/>
                    <p v-textMotion>
                        <span class="textMotion_target">
                            AI 견적 받고 가상창업을 통해 검증하여 실제 창업에 성공하세요! <br/>
                            위아오너는 창업에 대한 과정을 4단계로 구분하여 서비스를 제공합니다.
                            <!-- 데버에서는 클라이언트의 성공적인 비즈니스를 서포트하기 위해 <br/> -->
                            <!-- 정확한 분석과 설계, 검증된 개발 노하우로 <span class="text_deco"> 최적의 솔루션을 제공합니다.</span> -->
                        </span>
                    </p>
                    <p v-textMotion>
                        <span class="textMotion_target">
                            <ol>
                            <li>첫째, 창업준비는 AI 비교견적으로!</li>
                            <li>둘째, 창업검증은 가상창업으로 경험(준비 중)</li>
                            <li>셋째, 창업실행은 업종별 매칭서비스!</li>
                            <li>넷쩨, 사업운영은 오너장터로!</li>
                            </ol>
                        </span>
                    </p>
                    <p v-textMotion>
                        <span class="textMotion_target">
                            <span class="text_deco">창업의 시작, 폐업, 재창업</span>까지 원스톱 서비스를 제공합니다. <br/>
                            우리 모두 오너가 되는 그날까지 위아오너!
                        </span>
                    </p>
                </div>
            </div>
        </section>
        <!-- 개요 END -->

        <!-- 중간배너 START -->
        <!-- !!!!!!!!!슬라이드로 만들기 -->
        <section class="c_section banner">
            <div class="c_inner">
                <div class="img_wrapper it">
                    <div class="icon_wrapper">
                        <!-- <img v-fadein:reverse src="@/assets/images/banner/icon_it.png" alt="아이콘" class="icon" /> -->
                        <!-- <br/> -->
                        <!-- <h2 v-fadein:reverse>IT 기술로 모든 것에 가치를 더합니다.</h2> -->
                        <!-- <h2 v-fadein:reverse>우리는 더 나은 디지털 경험을 만듭니다.</h2> -->
                        <h2 v-fadein:reverse>우리는 업종별 오너를 위한 네비게이터입니다.</h2>
                    </div>
                </div>
            </div>
        </section>
        <!-- 중간배너 END -->

        <!-- 서비스 START -->
        <section class="c_section service">
            <div class="c_inner">
                <ul>
                    <ServiceListBox
                        which="web" title="창업정보"
                        :image="require('@/assets/images/main/img_service_001.png')"
                        :keyword="['창업연구소', '창업예산', '창업사례']"
                        opposite="opposite"
                    >
                        창업의 첫 걸음, 업종별 맞춤 정보를 제공합니다. <br/>
                        업종 선정부터 창업 준비과정을 함께해보세요!
                    </ServiceListBox>
                    <ServiceListBox
                        which="app" title="비교견적"
                        :image="require('@/assets/images/main/img_service_02.png')"
                        :keyword="['AI 견적', '견적서 다운로드', '창업 코디네이터']"
                    >
                        위아오너를 모르는 사람은 창업이 어렵습니다. <br/>
                        똑똑한 AI와 창업 코디네이터를 통해 창업비용을 절감해보세요!
                    </ServiceListBox>
                    <ServiceListBox
                        which="commerce" title="이커머스"
                        :image="require('@/assets/images/main/img_service_03.png')"
                        :keyword="['창업마켓', '렌탈', '할부창업']"
                        opposite="opposite"
                    >
                        필요한 물품, 이곳 저곳 찾기 힘드셨나요? <br/>
                        업종별 필요 물품을 일괄 구매할 수 있어요.
                    </ServiceListBox>
                    <ServiceListBox
                        which="solution" title="사업운영"
                        :image="require('@/assets/images/main/img_service_04.png')"
                        :keyword="['개발 디자인', '마케팅 구인', '세무 보험 투자']"
                    >
                        힘들게 준비해서 시작한 사업 <br/>
                        이제는 Scale-Up을 통해 성공해보세요!
                    </ServiceListBox>
                </ul>
            </div>
        </section>
        <!-- 서비스 END -->

        <!-- 비전배너 START -->
        <section class="c_section banner banner_vision">
            <div class="c_inner">
                <div class="img_wrapper vision">
                    <div class="icon_wrapper">
                        <!-- <img src="@/assets/images/banner/icon_vision.png" alt="아이콘" class="icon" /> -->
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion>
                        <span class="textMotion_target">
                            <span class="text_deco">최고의 방법과 최고의 서비스</span>를 만드는 목표로 
                        </span><br/>
                        <span class="textMotion_target">위아오너는 다음의 가치를 지키고 있습니다.</span>
                    </h2>
                </div>
            </div>
        </section>
        <!-- 비전배너 END -->

        <!-- 비전 START -->
        <section class="c_section vision">
            <div class="c_inner">
                <swiper v-bind="swiperOption">
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_01.png" alt="태도" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2>Our <strong class="attitude">Attitude</strong></h2>
                                <p>사소한 것 까지 관찰하고 탐구합니다.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_02.png" alt="협력" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2>Our <strong class="cooperation">Cooperation</strong></h2>
                                <p>상대에 대한 존중과 배려는 위아오너의 기본입니다.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_04.png" alt="책임" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2>Our <strong class="responsibility">Responsibility</strong></h2>
                                <p>자유로움 속에서 언제나 책임을 가집니다.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_03.png" alt="동기 부여" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2>Our <strong class="motivation">Motivation</strong></h2>
                                <p>우리는 최고의 서비스 제공을 목표로 합니다.</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </section>
        <!-- 비전 END -->

        <!-- 하단배너 START -->
        <section class="c_section banner banner_contact">
            <div class="c_inner">
                <div class="img_wrapper contact">
                    <div class="icon_wrapper">
                        <!-- <img src="@/assets/images/banner/icon_contact.png" alt="아이콘" class="icon" /> -->
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion>
                        <span class="textMotion_target">사업제휴, 컨설팅, 개발 견적, 채용 등</span><br/>
                        <span class="textMotion_target"><span class="text_deco">문의사항</span>이 있으신가요?&nbsp;</span><br/>
                    </h2>
                </div>
            </div>
        </section>
        <!-- 하단배너 END -->

        <!-- 컨택트 START -->
        <section class="c_section contact_content">
            <div class="c_inner">
                <div class="l_company_info">
                    <dl v-textMotion class="text">
                        <dd class="textMotion_target">
                            보내주시는 소중한 제안과 문의는 검토 후에 신속하게 회신드리겠습니다 :-)
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_location.png" alt="아이콘" />
                        </dt>
                        <dd>경기도 고양시 일산동구 중앙로 1193, 6 층</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_email.png" alt="아이콘" />
                        </dt>
                        <dd>master@ownersite.co.kr</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_tel.png" alt="아이콘" />
                        </dt>
                        <dd>0507-1387-6003</dd>
                    </dl>
                </div>

                <div class="btn_wrapper">
                    <button @click="this.$router.push('/contact')" class="c_btn_common c_btn_primary btn_submit">Contact Us!</button>
                </div>
            </div>
        </section>
        <!-- 컨택트 END -->
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

import SwiperCore, { EffectCreative, Pagination, Navigation, Autoplay } from "swiper";
SwiperCore.use([ EffectCreative, Pagination, Navigation, Autoplay ]);


import ServiceListBox from '@/components/Home/ServiceListBox'

export default {
    name: 'Home',
    components: {
        Swiper, SwiperSlide,
        ServiceListBox,
    },
    data(){
        return {
            windowSize : window.innerWidth,
            swiperOption: {
                effect: 'creative',
                loop: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},
                slidesPerView : 1.05,
                pagination: {
                    type: 'fraction',
                },
                navigation: true,
                creativeEffect: {
                    prev: {
                        translate: ['-120%', 0, 0],
                        scale: 0.5
                    },
                    next: {
                        translate: ['50%', '-20%', 0],
                        scale: 0.5,
                    },
                }
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.parallaxSlogun);
        window.addEventListener('scroll', this.videoScaleUp);
        window.addEventListener('resize', this.getWindowSize);
    },
    unmounted(){
        window.removeEventListener('scroll', this.parallaxSlogun);
        window.removeEventListener('scroll', this.videoScaleUp);
        window.removeEventListener('resize', this.getWindowSize);
    },
    methods: {
        parallaxSlogun(){
            const slogun = document.querySelector('.slogun');
            const parallax = document.querySelector('.parallax');

            if(window.scrollY*(0.4) <  parallax.offsetHeight/2 ){
                slogun.style.opacity = 1;
                slogun.style.transform = 'translateY(0)';
                parallax.style.transform = `translateY(${window.scrollY * 0.4}px)`;
            } else {
                slogun.style.opacity = 0;
                slogun.style.transform = 'translateY(15%)';
            }
        },
        videoScaleUp(){
            const movieEl = document.querySelector('.moive');
            const videoEl = document.querySelector('.moive .display video');

            const topHeight = movieEl.parentElement.offsetTop + movieEl.offsetTop;  // 맨 위 위치
            const endHeight = movieEl.parentElement.offsetTop + movieEl.offsetTop + movieEl.offsetHeight - window.innerHeight;  // 맨 끝 위치
            const totalHeight = endHeight - topHeight;    // 총 길이
            let diff = window.scrollY - topHeight;      // 현재 스크롤에서 내려온 거리

            if(0 <= diff && diff <= totalHeight/2){
                videoEl.style.transform = `scale(${1+(0.3/totalHeight*diff)})`
            } else if(totalHeight/2 < diff && diff <= totalHeight){
                videoEl.style.transform = `scale(${1.3-(0.3/totalHeight)*diff})`
            } else {
                videoEl.style.transform = 'scale(1)'
            }
        },
        getWindowSize(){
            this.windowSize = window.innerWidth;
        }
    }
}
</script>

<style scoped src="@/assets/css/main.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>