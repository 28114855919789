<template>
    <li :class="this.which">
        <div class="img_wrapper">
            <div v-circleMotion:reverse class="align">
                <img :src="this.image" alt="아이콘" />
            </div>
        </div>
        <div class="text_wrapper">
            <div class="text">
                <h2 v-textMotion:[opposite]>
                    <span class="textMotion_target"><span class="text_deco">{{title}}</span></span>
                </h2>
                <br/>
                <p v-fadein:reverse="{time:2, delay:1}">
                    <slot>SampleSampleSampleSampleSampleSampleSampleSampleSampleSample</slot>
                </p>
                <div class="keyword">
                    <span
                        v-for="item in this.keyword" :key="item"
                        v-textMotion:[opposite]="{rootMargin : '-5% 0px -10%'}"
                    >
                        <span class="textMotion_target">{{item}}</span>
                    </span>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    props:{
        which : String,
        title : String,
        image : String,
        keyword : Array,
        opposite : String
    },
}
</script>

<style scoped>
li {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    position: relative;
}
li:nth-child(odd) {
    text-align: right;
}
li:nth-child(even) {
    flex-direction: row-reverse;
}
li .img_wrapper {
    flex-basis: 50%;
    position: relative;
    transition: transform 0.12s;
}
li .img_wrapper::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
li .img_wrapper .align {
    position: absolute;
    width: 100%;
    height: 100%;
}
li .img_wrapper .align img{
    border-radius: 50%;
    overflow: hidden;
}
li .text_wrapper {
    flex-basis: 60%;
}
li:nth-child(odd) .text_wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: unset;
    right: 0;
    transform: translate(0%, -50%);
}
li:nth-child(even) .text_wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: unset;
    transform: translate(0%, -50%);
}
li .icon_wrapper {
    display: inline-block;
    max-width: 4rem;
    max-height: 4rem;
}
li .text h2 {
    line-height: 1;
}
li .text p {
    margin: 3rem 0;
    font-size: var(--font-size-x-large);
    font-weight: var(--font-w-regular);
    overflow: hidden;
}
@media screen and (max-width:768px){
    li .text p{
        font-size:var(--font-size-large);
    }
}
@media screen and (max-width:479px){
    li .text p{
        font-size:var(--font-size-mid);
    }
}
li .text .keyword {
    color: var(--color-primary);
    font-size: var(--font-size-mid);
}
li .text .keyword span + span {
    margin-left: 2rem;
}
li .text .keyword .textMotion_on>span{
    padding-right: 0.5rem;
}
li .text .keyword .textMotion_opposite_on>span{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

/* 키워드 색상 제어 CSS START */
.web .text .keyword {
    color: var(--color-special_01);
}
.app .text .keyword {
    color: var(--color-special_02);
}
.commerce .text .keyword {
    color: var(--color-special_03);
}
.solution .text .keyword {
    color: var(--color-special_04);
}
.web .text_deco{
    background: linear-gradient(transparent 60%, var(--color-special_01) 35%);
}
.app .text_deco{
    background: linear-gradient(transparent 60%, var(--color-special_02) 35%);
}
.commerce .text_deco{
    background: linear-gradient(transparent 60%, var(--color-special_03) 35%);
}
.solution .text_deco{
    background: linear-gradient(transparent 60%, var(--color-special_04) 35%);
}
/* 키워드 색상 제어 CSS END */

@media screen and (max-width: 1024px) {
    li .img_wrapper {
        opacity: 0.5;
    }
}
@media screen and (max-width: 768px) {
    li {
        flex-wrap: wrap;
        justify-content: center;
    }
    li .img_wrapper {
        flex-basis: 80%;
        opacity: unset;
    }
    li:nth-child(odd) .text_wrapper,
    li:nth-child(even) .text_wrapper {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        transform: unset;
    }
    li .text_wrapper {
        flex-basis: 100%;
    }
    li .text_wrapper .text p >>> br {
        display: none;
    }
}

/* 색상 속성 START */
.web .textMotion_target::after{
	background-color: var(--color-special_01);
}
.app .textMotion_target::after{
	background-color: var(--color-special_02);
}
.commerce .textMotion_target::after{
	background-color: var(--color-special_03);
}
.solution .textMotion_target::after{
	background-color: var(--color-special_04);
}

.web .circleMotion_on::before,
.web .circleMotion_on::after{
    border-color: var(--color-special_01);
}
.app .circleMotion_on::before,
.app .circleMotion_on::after{
    border-color: var(--color-special_02);
}
.commerce .circleMotion_on::before,
.commerce .circleMotion_on::after{
    border-color: var(--color-special_03);
}
.solution .circleMotion_on::before,
.solution .circleMotion_on::after{
    border-color: var(--color-special_04);
}
/* 색상 속성 END */

</style>