import { createStore } from 'vuex'

export default createStore({
    state: {
        alertContent : '',  // 알림창 내용
        confirmContent : '',    // 확인창 내용
        confirmCallback : null,
        errorContent : '',    // 에러창 내용
        
        loading : false,    // 로딩
    },
    mutations: {
        // 알림창
        alert(state, payload){
            state.alertContent = payload;
            this.alertTimeout = setTimeout(() => {
                state.alertContent = '';
            }, 2000);
        },
        closeAlert(state){
            state.alertContent = '';
            clearTimeout(this.alertTimeout);
        },

        // 확인(confirm)창
        confirm(state, payload){
            state.confirmContent = payload.content;
            state.confirmCallback = payload.callback;
        },
        confirmContinue(state){
            state.confirmContent = '';
            state.confirmCallback();
        },
        confirmCancel(state){
            state.confirmContent = '';
            state.confirmCallback = null;
        },

        // 에러창
        error(state, payload){
            state.errorContent = payload;
        },
        closeError(state){
            state.errorContent = '';
        },

        // 로딩
        startLoading(state){
            state.loading = true;
        },
        finishLoading(state){
            state.loading = false;
        }
    },
    actions: {
    },
    modules: {
    },
})
