<template>
    <footer class="footer_container">
        <div class="footer">
            <div class="footer_left">
                <ul class="menu">
                    <li><router-link to="/">회사소개</router-link></li>
                    <li><a target="_blank" href="https://childish-wholesaler-4f8.notion.site/576a7d7bf2fa452ba10f84a43bf1f292">이용약관</a></li>
                    <li><a target="_blank" href="https://childish-wholesaler-4f8.notion.site/bccc8f5e277e482692f1f4509bb25cf5">개인정보취급방침</a></li>
                    <!-- <li><router-link to="/contact">Contact</router-link></li> -->
                    <!-- <li><router-link to="/about">About</router-link></li> -->
                </ul>
                <div class="footer_info">
                    <div>
                        <span>(주)위아오너</span>
                        <address>대표이사 노상호</address>
                        <span>경기 고양시 일산동구 중앙로 1193, 6층</span>
                        <address class="ad_last">T. 0507-1387-6003</address>
                    </div>
                    <div>
                        <span>master@ownersite.co.kr</span>
                        <address>사업자등록번호 : 803-87-01355</address>
                        <span>개인정보보호 책임자 : 전성환</span>
                        <address class="ad_last">통신판매번호 : 2019-고양일산동구-1373</address>
                    </div>
                    <div>
                        <span>Copyright WeAreOwner 2019. All Rights Reserved.</span>
                    </div>
                </div>
            </div>
            <div class="footer_right">
                <details class="f_select">
                    <summary>
                        <span>관련 사이트</span>
                        <div class="btn_wrapper">
                            <img src="@/assets/images/common/icon_arrow_down_w.svg"/>
                        </div>
                    </summary>
                    <ul>
                        <li><a target="_blank" href="https://introduce.weareowner.co.kr/">앱 다운로드</a></li>
                        <li><a target="_blank" href="https://mall.weareowner.co.kr">오너몰</a></li>
                    </ul>
                </details>
                <!-- <div class="footer_info">
                    <div>
                        <span>데버 주식회사 대표이사 : 임종건</span>
                        <address>서울시 중구 을지로 5길 19 페럼타워 23층</address>
                        <span>info@devercorp.com</span>
                    </div>
                    <div>
                        <span>ⓒ DEVER corp. All rights reserved.</span>
                    </div>
                </div> -->
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped src="@/assets/css/components/footer.css">
</style>