
// 스타일 적용
function setStyle(el, binding, isInit=false){
    if(isInit){ // 초기화
        el.style.opacity = 0;
        el.style.transition = `all ${binding.value && binding.value.time ? binding.value.time : 0.7}s`;
        if(binding.modifiers.vertical){ // 수직
            el.style.transform = `translateY(${binding.value && binding.value.percent ? binding.value.percent : '30%'})`;
        } else{ // 수평
            el.style.transform = `translateX(${binding.value && binding.value.percent ? binding.value.percent : '-20%'})`;
        }
    } else {
        el.style.opacity = 1;
        el.style.transform = 'translateY(0) translateX(0)';
    }
}

function init(el, binding){
    if(binding.modifiers.child){    // 자식 요소에 차례로 효과 넣을 때
        const childrenEl = el.childNodes;
        for(let i=0; i<childrenEl.length; i++){
            setStyle(childrenEl[i], binding, true);
        }
    } else {    // 해당 요소에 직접 효과 넣을 때
        setStyle(el, binding, true);
    }
}

// v-slidein
// v-slidein:reverse.child.vertical="{ time: 0.7, percent: -10%, delay: 0 }"
const slidein = {
    beforeMount(el, binding) {  // 초기화
        init(el, binding);
    },
    mounted(el, binding) {
        let timeoutID = [];

        function motion() { // 모션
            if(binding.modifiers.child){    // 자식 요소에 차례로 효과 넣을 때
                const childrenEl = el.childNodes;
                for(let i=0; i<childrenEl.length; i++){
                    let tempID = setTimeout(() => {
                        setStyle(childrenEl[i], binding);
                        timeoutID.splice(timeoutID.indexOf(tempID), 1);
                    }, i*(binding.value && (binding.value.delay || binding.value.delay===0) ? binding.value.delay : 160));
                    timeoutID.push(tempID);
                }
            } else {    // 해당 요소에 직접 효과 넣을 때
                let tempID = setTimeout(() => {
                    setStyle(el, binding);
                    timeoutID.splice(timeoutID.indexOf(tempID), 1);
                }, binding.value && (binding.value.delay || binding.value.delay===0) ? binding.value.delay : 0);
                timeoutID.push(tempID)
            }
        }

        function createObserver() { // InsertionObserver 생성
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) { // 감지대상이 교차영역에 진입 할 경우
                        motion();
                        if(binding.arg != 'reverse'){
                            observer.unobserve(el); // reverse 아니면 관찰할 필요 x
                        }
                    } else {    // 감지대상이 교차영역에서 나간 경우
                        if(binding.arg == 'reverse'){
                            timeoutID.forEach(idx=>{
                                clearTimeout(idx);
                            })
                            timeoutID = [];
                            init(el, binding);
                        }
                    }
                });
            }, {
                rootMargin: binding.value && binding.value.rootMargin ? binding.value.rootMargin : '0% 0px -6%',
                threshold: binding.value && binding.value.threshold ? binding.value.threshold : 0,
            });

            observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 모션을 시켜도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : motion();
    }
};

export default slidein;
