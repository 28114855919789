import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import { VueReCaptcha } from 'vue-recaptcha-v3'

import textMotion from './directive/textMotion'
import fadein from './directive/fadein'
import slidein from './directive/slidein'
import circleMotion from './directive/circleMotion'
import imageMotion from './directive/imageMotion'

// axios 기본 url
axios.defaults.baseURL = 'https://api.devercorp.com/api';

const app = createApp(App);

app.directive("textMotion", textMotion);
app.directive("fadein", fadein);
app.directive("slidein", slidein);
app.directive("circleMotion", circleMotion);
app.directive("imageMotion", imageMotion);

// app.use(VueReCaptcha, {siteKey: '6Lca_LEeAAAAACAoctKFPEIDm7C5S507ltsEZa93'})

app.config.globalProperties.axios = axios;
app.use(store).use(router).mount('#app');