<template>
  <!--header START-->
  <div class="header_container">
    <div class="header_bar difference" ref="header_bar">
      <router-link to="/" @click="onGnbToggle('remove')">
        <svg
          version="1.1"
          class="logo"
          id="logo"
          fill="#231815"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 1080"
        >
          <g>
            <path
              class="st0"
              d="M950.8,701.4h5.1l12.8,44.7c1.5,6.2,3.2,11.8,4.6,17.6h0.6c1.6-5.8,3.3-11.4,4.9-17.6l12.8-44.7h6.1
                            l12.8,44.7c1.6,6.2,3.3,11.8,5.1,17.6h0.5c1.5-5.8,3.2-11.4,4.9-17.6l12.4-44.7h4.8l-19.5,66.7h-6.2l-12.4-43.2
                            c-2-6.5-3.4-12.5-5.3-19h-0.6c-1.6,6.5-3.3,12.8-5.4,19.2l-12.2,42.9h-5.6L950.8,701.4z"
            />
            <path
              class="st0"
              d="M1048.9,734.9c0-21.8,13.9-35.2,28.7-35.2c15.4,0,25.2,11,25.2,30.8c0,1.4,0,2.9-0.3,4.6h-48.9
                            c0,17.6,10.3,30.5,25.9,30.5c7.6,0,13.3-2.4,18.4-6.1l2,3.9c-5.1,3-10.8,6.3-20.6,6.3C1062.6,769.7,1048.9,756.7,1048.9,734.9z
                            M1098.4,730.8c0-18.1-8.2-27-20.8-27c-11.9,0-22.5,10.1-23.9,27H1098.4z"
            />
            <path
              class="st0"
              d="M1144.3,751.6c0-14.8,13.5-21.8,43.5-25.2c0.4-10.8-2.4-22.5-16.2-22.5c-9.5,0-17.5,4.7-21.8,8.1l-2.3-3.7
                            c4.2-3,13.4-8.6,24.3-8.6c15.7,0,20.5,11.6,20.5,25.2v43.2h-3.8l-0.6-8.9h-0.4c-6.8,5.6-15.2,10.5-24.1,10.5
                            C1153.2,769.7,1144.3,764.3,1144.3,751.6z M1187.9,754.3v-23.9c-29,3.2-38.9,9.9-38.9,21.1c0,10.3,6.8,14.1,14.9,14.1
                            C1171.9,765.5,1179.1,761.7,1187.9,754.3z"
            />
            <path
              class="st0"
              d="M1217.1,701.4h3.8l0.8,12.5h0.3c4.4-8.1,10.9-14.2,18.9-14.2c2.3,0,4.2,0.3,6.5,1.4l-1.1,4.2
                            c-2.2-0.9-3.3-1.1-6-1.1c-5.9,0-13.3,4.7-18.5,17.8v46.1h-4.6V701.4z"
            />
            <path
              class="st0"
              d="M1252.8,734.9c0-21.8,13.9-35.2,28.7-35.2c15.4,0,25.2,11,25.2,30.8c0,1.4,0,2.9-0.3,4.6h-48.9
                            c0,17.6,10.3,30.5,25.9,30.5c7.6,0,13.3-2.4,18.4-6.1l2,3.9c-5.1,3-10.8,6.3-20.6,6.3C1266.5,769.7,1252.8,756.7,1252.8,734.9z
                            M1302.3,730.8c0-18.1-8.2-27-20.8-27c-11.9,0-22.5,10.1-23.9,27H1302.3z"
            />
            <path
              class="st0"
              d="M1347.4,734.9c0-22.4,13.7-35.2,29.6-35.2c15.9,0,29.6,12.8,29.6,35.2c0,22-13.7,34.8-29.6,34.8
                            C1361,769.7,1347.4,756.9,1347.4,734.9z M1401.9,734.9c0-18.4-10.8-31-24.9-31c-14.2,0-24.8,12.7-24.8,31
                            c0,18.2,10.6,30.6,24.8,30.6C1391.2,765.5,1401.9,753.1,1401.9,734.9z"
            />
            <path
              class="st0"
              d="M1417.4,701.4h5.1l12.8,44.7c1.5,6.2,3.2,11.8,4.6,17.6h0.6c1.6-5.8,3.3-11.4,4.9-17.6l12.8-44.7h6.1
                            L1477,746c1.6,6.2,3.3,11.8,5.1,17.6h0.5c1.5-5.8,3.2-11.4,4.9-17.6l12.4-44.7h4.8l-19.5,66.7h-6.2l-12.4-43.2
                            c-2-6.5-3.4-12.5-5.3-19h-0.6c-1.6,6.5-3.3,12.8-5.4,19.2l-12.2,42.9h-5.6L1417.4,701.4z"
            />
            <path
              class="st0"
              d="M1521.3,701.4h3.8l0.8,10.6h0.3c6.8-7,13.8-12.3,23-12.3c13.7,0,20.1,8.2,20.1,25.4v42.9h-4.6v-42.3
                            c0-14.7-4.7-21.9-16.1-21.9c-8.2,0-14.3,4.4-22.8,13.2v51h-4.6V701.4z"
            />
            <path
              class="st0"
              d="M1588.9,734.9c0-21.8,13.9-35.2,28.7-35.2c15.4,0,25.2,11,25.2,30.8c0,1.4,0,2.9-0.3,4.6h-48.9
                            c0,17.6,10.3,30.5,25.9,30.5c7.6,0,13.3-2.4,18.4-6.1l2,3.9c-5.1,3-10.8,6.3-20.6,6.3C1602.6,769.7,1588.9,756.7,1588.9,734.9z
                            M1638.4,730.8c0-18.1-8.2-27-20.8-27c-11.9,0-22.5,10.1-23.9,27H1638.4z"
            />
            <path
              class="st0"
              d="M1661.4,701.4h3.8l0.8,12.5h0.3c4.4-8.1,10.9-14.2,18.9-14.2c2.3,0,4.2,0.3,6.5,1.4l-1.1,4.2
                            c-2.2-0.9-3.3-1.1-6-1.1c-5.9,0-13.3,4.7-18.5,17.8v46.1h-4.6V701.4z"
            />
          </g>
          <path
            class="st0"
            d="M330.6,818.5c-161,0-292-131-292-292h20.2c0,149.8,121.9,271.7,271.7,271.7s271.7-121.9,271.7-271.7h20.2
                        C622.5,687.5,491.6,818.5,330.6,818.5z"
          />
          <path
            class="st0"
            d="M485.4,511.3c0-20.1-11.5-37.5-28.3-46c8.2-9.8,12.4-22,12-34.9c-1-27.4-24.5-49.6-52.4-49.6h-40.3v-63.9
                        c0-30.6-24.9-55.4-55.4-55.4s-55.4,24.9-55.4,55.4v67.3c-51.5,12.8-89.7,59.3-89.7,114.7v87.2c0,65.1,53,118.1,118.1,118.1h105.9
                        c23.8,0,43.2-18.3,44-41.7c0.3-9.4-2.3-18.3-7.5-25.9c17.3-8.3,29.3-26,29.3-46.5c0-12-4.1-23.1-11.1-31.8
                        C472.7,550.4,485.4,532.3,485.4,511.3L485.4,511.3z M289.5,316.9c0-17.3,14.1-31.4,31.4-31.4c17.3,0,31.4,14.1,31.4,31.4v63.9
                        h-58.4c-1.5,0-3,0.1-4.4,0.1V316.9z M433.9,538.7h-109c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12h89.3c15.2,0,27.5,12.3,27.5,27.5
                        c0,15.2-12.3,27.5-27.5,27.5h-89.3c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12h75.6c5.3,0,10.2,2.1,13.9,5.9c3.7,3.8,5.6,8.8,5.4,14.1
                        c-0.4,10.2-9.4,18.6-20,18.6H293.9c-51.9,0-94.2-42.2-94.2-94.2v-87.2c0-51.9,42.2-94.2,94.2-94.2h122.7
                        c15.2,0,27.9,11.9,28.4,26.5c0.3,7.5-2.5,14.7-7.7,20.1c-5.2,5.4-12.3,8.4-19.8,8.4h-92.7c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12
                        h109c15.2,0,27.5,12.3,27.5,27.5C461.4,526.4,449,538.7,433.9,538.7L433.9,538.7z M433.9,538.7"
          />
          <g>
            <path
              class="st0"
              d="M788.1,584.1c4.4-0.6,8.7-1.6,12.9-3c4.1-1.4,7.8-3.3,10.9-5.9c3.1-2.6,5.5-5.6,7.4-9.2
                            c1.8-3.5,2.7-7.7,2.7-12.4V543h-47.5c-8.7,0-15-2.5-18.8-7.5c-3.9-5-5.8-10.7-5.8-17c0-3,0.4-5.9,1.3-8.9c0.9-3,2.3-5.6,4.3-8
                            c2-2.4,4.6-4.3,7.7-5.8c3.1-1.5,6.8-2.2,11.3-2.2h146.8c4.4,0,8.2,0.7,11.3,2.2c3.1,1.5,5.6,3.4,7.5,5.8c1.9,2.4,3.3,5,4.2,8
                            c0.9,3,1.3,5.9,1.3,8.9c0,3.2-0.4,6.2-1.3,9.2c-0.9,3-2.3,5.6-4.3,7.8c-2,2.3-4.6,4.1-7.7,5.5c-3.1,1.4-6.8,2.1-11,2.1H881v10.6
                            c0,12.8-2.4,23.9-7.1,33.4c-4.7,9.4-11,17.4-18.7,23.8c-7.7,6.4-16.6,11.2-26.6,14.5c-10,3.3-20.4,5-31,5.2
                            c-8.1,0.2-15.1-1.7-20.8-5.8c-5.8-4-8.8-9.6-9-16.7C767.6,593.6,774.4,585.6,788.1,584.1z M810.1,476.2c-9.5,0-17.8-1.5-24.9-4.6
                            c-7.1-3.1-13.1-7.1-17.8-12.3c-4.7-5.1-8.3-11.2-10.7-18.2c-2.4-7-3.6-14.5-3.6-22.6v-0.9c0-7.9,1.2-15.4,3.6-22.4
                            c2.4-7.1,6-13.2,10.7-18.3c4.7-5.1,10.7-9.2,17.8-12.3c7.1-3.1,15.4-4.6,24.9-4.6h68.3c9.5,0,17.7,1.5,24.7,4.6
                            c7,3.1,13,7.1,17.8,12.3c4.8,5.1,8.4,11.2,10.9,18.3c2.4,7.1,3.6,14.6,3.6,22.4v0.9c0,8.1-1.2,15.6-3.6,22.6
                            c-2.4,7-6,13-10.9,18.2c-4.8,5.1-10.8,9.2-17.8,12.3c-7,3.1-15.3,4.6-24.7,4.6H810.1z M810.9,418.9c0,3.9,1.3,7.3,4.1,10
                            c2.7,2.8,7.4,4.1,14.2,4.1h30.4c6.8,0,11.4-1.4,14-4.1c2.6-2.8,3.9-6.1,3.9-10v-1.5c0-3.9-1.3-7.3-3.9-10.2
                            c-2.6-2.9-7.3-4.3-14-4.3h-30.4c-6.8,0-11.5,1.4-14.2,4.3c-2.7,2.9-4.1,6.3-4.1,10.2V418.9z M955.1,628.1V380
                            c0-9.6,2.9-16.9,8.7-21.9c5.8-4.9,12.5-7.4,20.3-7.4c7.7,0,14.5,2.5,20.3,7.4c5.8,4.9,8.7,12.2,8.7,21.9v248.1H955.1z"
            />
            <path
              class="st0"
              d="M1046.9,434.9c0-13,2-24.5,5.9-34.4c4-9.9,9.5-18.3,16.5-25c7-6.7,15.5-11.7,25.3-15.1c9.8-3.3,20.7-5,32.7-5
                            c11.8,0,22.6,1.7,32.4,5c9.8,3.3,18.3,8.4,25.5,15.1c7.1,6.7,12.6,15,16.5,25c3.9,9.9,5.8,21.4,5.8,34.4V543
                            c0,13-1.9,24.5-5.8,34.4c-3.9,9.9-9.4,18.3-16.5,25c-7.1,6.7-15.6,11.7-25.5,15.1c-9.8,3.3-20.7,5-32.4,5c-12,0-22.9-1.7-32.7-5
                            c-9.8-3.3-18.3-8.4-25.3-15.1c-7-6.7-12.5-15-16.5-25c-4-9.9-5.9-21.4-5.9-34.4V434.9z M1103.6,544.5c0,10,2.2,17.5,6.7,22.3
                            c4.4,4.8,10.1,7.2,17.1,7.2c6.9,0,12.6-2.4,17.1-7.2c4.4-4.8,6.7-12.3,6.7-22.3V433.4c0-10-2.2-17.5-6.7-22.3
                            c-4.4-4.8-10.1-7.2-17.1-7.2c-6.9,0-12.6,2.4-17.1,7.2c-4.4,4.8-6.7,12.3-6.7,22.3V544.5z M1236.7,379.4c0-9.5,2.9-16.6,8.7-21.4
                            c5.8-4.8,12.4-7.2,20-7.2c3.7,0,7.2,0.6,10.7,1.9c3.5,1.3,6.5,3.1,9.1,5.5c2.6,2.4,4.7,5.3,6.4,8.9c1.6,3.5,2.5,7.7,2.5,12.4v74.7
                            h9.6c4.4,0,8.1,0.8,11,2.4c2.9,1.6,5.2,3.6,6.9,6.1c1.7,2.5,3,5.2,3.8,8.3c0.8,3.1,1.2,6.1,1.2,9c0,3-0.4,6-1.2,9
                            c-0.8,3.1-2,5.8-3.8,8.3c-1.7,2.5-4.1,4.5-7.1,6.1c-3,1.6-6.6,2.4-10.9,2.4h-9.6v122.6h-57.3V379.4z"
            />
            <path
              class="st0"
              d="M1434.7,574.3v-55.2H1413c-12.2,0-23-2.1-32.4-6.2c-9.5-4.1-17.4-9.7-23.7-16.8c-6.4-7.1-11.2-15.3-14.6-24.7
                            c-3.4-9.4-5.1-19.1-5.1-29.4v-6.5c0-10.2,1.7-20,5.1-29.4c3.4-9.3,8.3-17.6,14.6-24.7c6.4-7.1,14.3-12.7,23.7-17
                            c9.5-4.2,20.3-6.4,32.4-6.4h102.5c12.2,0,23,2.1,32.4,6.4c9.5,4.2,17.4,9.9,23.7,17c6.4,7.1,11.2,15.3,14.6,24.7
                            c3.4,9.4,5.1,19.2,5.1,29.4v6.5c0,10.2-1.7,20-5.1,29.4c-3.4,9.4-8.2,17.6-14.6,24.7c-6.4,7.1-14.3,12.7-23.7,16.8
                            c-9.5,4.1-20.3,6.2-32.4,6.2h-21.7v55.2h81.6c4.4,0,8.2,0.7,11.3,2.1c3.1,1.4,5.6,3.2,7.5,5.5c1.9,2.3,3.3,4.8,4.2,7.7
                            c0.9,2.9,1.3,5.8,1.3,8.7c0,6.1-1.9,11.6-5.6,16.5c-3.8,4.9-10,7.4-18.7,7.4h-222.3c-8.7,0-14.9-2.5-18.7-7.4
                            c-3.8-4.9-5.6-10.4-5.6-16.5c0-3,0.4-5.9,1.3-8.7c0.9-2.9,2.3-5.4,4.2-7.7c1.9-2.3,4.4-4.1,7.5-5.5c3.1-1.4,6.8-2.1,11.3-2.1
                            H1434.7z M1531.1,434.9c0-8.3-2.8-15.1-8.4-20.5c-5.6-5.4-13.2-8.1-22.9-8.1h-71.2c-9.7,0-17.3,2.7-22.9,8.1
                            c-5.6,5.4-8.4,12.3-8.4,20.5v7.7c0,8.3,2.8,15.1,8.4,20.5c5.6,5.4,13.2,8.1,22.9,8.1h71.2c9.6,0,17.3-2.7,22.9-8.1
                            c5.6-5.4,8.4-12.3,8.4-20.5V434.9z"
            />
            <path
              class="st0"
              d="M1777.2,563.4c4.2,0,7.8,0.8,10.6,2.4c2.8,1.6,5,3.5,6.7,5.9c1.6,2.4,2.8,5,3.5,8c0.7,3,1,5.8,1,8.6
                            c0,3-0.3,5.9-1,8.9c-0.7,3-1.9,5.6-3.6,8c-1.7,2.4-4,4.3-6.8,5.9c-2.8,1.6-6.2,2.4-10.3,2.4h-105.7c-8.7,0-15.9-0.8-21.6-2.4
                            c-5.7-1.6-10.2-4.2-13.5-7.8c-3.3-3.6-5.6-8.4-6.9-14.2c-1.4-5.8-2-12.9-2-21.4V390.6c0-9.8,2.9-17,8.7-21.4
                            c5.8-4.4,12.6-6.6,20.6-6.6c8.1,0,14.9,2.2,20.3,6.6c5.4,4.4,8.1,11.6,8.1,21.4v164.8c0,3.4,0.5,5.5,1.6,6.5
                            c1.1,1,3.2,1.5,6.5,1.5H1777.2z M1823.8,500.8h-55.9c-4.4,0-8.2-0.8-11.1-2.4c-3-1.6-5.5-3.6-7.5-6.1c-2-2.5-3.5-5.2-4.3-8.3
                            c-0.9-3.1-1.3-6.1-1.3-9s0.4-6,1.3-9c0.9-3.1,2.3-5.8,4.2-8.3c1.9-2.5,4.4-4.5,7.5-6.1c3.1-1.6,6.8-2.4,11.3-2.4h55.9v-70
                            c0-9.5,2.9-16.6,8.7-21.4c5.8-4.8,12.4-7.2,20-7.2c3.7,0,7.2,0.6,10.7,1.9c3.5,1.3,6.6,3.1,9.3,5.5c2.7,2.4,4.9,5.3,6.5,8.9
                            c1.6,3.5,2.5,7.7,2.5,12.4v248.7h-57.6V500.8z"
            />
          </g>
        </svg>
      </router-link>
      <div class="link_wrapper">
        <div class="link_list">
          <a href="https://introduce.ownerapp.co.kr/" target="_blank">
            <button type="button">
              <p>창업 플랫폼 앱 소개</p>
            </button>
          </a>
        </div>
        <div class="link_list">
          <a href="https://play.google.com/store/apps/details?id=com.dever.weareowner" target="_blank">
            <img src="@/assets/images/common/btn_google_bw.svg" />
          </a>
        </div>
        <div class="link_list">
          <a href="https://apps.apple.com/kr/app/%EC%9C%84%EC%95%84%EC%98%A4%EB%84%88/id1585476966" target="_blank">
            <img src="@/assets/images/common/btn_app_store.svg" />
          </a>
        </div>
      </div>
      <div class="btn_menu" @click="onGnbToggle">
        <div class="header_line"></div>
        <div class="header_line"></div>
        <div class="header_line"></div>
      </div>
    </div>
    <header ref="header">
      <Vue3Lottie ref="logo" :animationData="logoJSON" />
      <div class="header_inner">
        <!-- <div class="menu">
                    <router-link @click="onGnbToggle" to="/">
                        <span>Home</span>
                        <span>메인으로</span>
                    </router-link>
                </div> -->
        <div class="menu">
          <router-link to="/about" @click="onGnbToggle">
            <span>위아오너에 대해서</span>
            <span>About</span>
          </router-link>
        </div>
        <div class="menu">
          <router-link to="/work" @click="onGnbToggle">
            <span>위아오너의 서비스</span>
            <span>Service</span>
          </router-link>
        </div>
        <div class="menu">
          <router-link
            to="/contact"
            @click="onGnbToggle"
            :class="this.$route.path == '/contact/detail' ? 'router-link-active' : ''"
          >
            <span>언제든 연락하세요</span>
            <span>Contact</span>
          </router-link>
        </div>
        <div class="menu">
          <router-link to="/culture" @click="onGnbToggle">
            <span>창업 컨텐츠</span>
            <span>Contents</span>
          </router-link>
        </div>
        <!-- <div class="menu">
                    <router-link to="/temp" @click="onGnbToggle">
                        <span>Portfolio</span>
                        <span>데버의 작업물</span>
                    </router-link>
                </div> -->
      </div>
    </header>
  </div>
  <!-- header END -->
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import logoJSON from "@/assets/movie/owner_logo_motion.json";

export default {
  name: "Header",
  // mounted(){
  //     const browser = navigator.userAgent.toLowerCase();
  //     if (browser.indexOf("samsungbrowser") != -1) {
  //         document.querySelector('.header_bar').classList.remove('difference');
  //     }
  // },
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      logoJSON,
    };
  },
  watch: {
    $route() {
      this.onGnbToggle("remove");
    },
  },
  methods: {
    onGnbToggle(action = "toggle") {
      let timeoutId = null;

      if (action == "remove") {
        this.$refs.header.classList.remove("on");
        this.$refs.header_bar.classList.remove("on");
        clearTimeout(timeoutId);
      } else {
        this.$refs.header.classList.toggle("on");
        this.$refs.header_bar.classList.toggle("on");
        this.$refs["logo"].stop();
        timeoutId = setTimeout(() => {
          console.log("안녕");
          this.$refs["logo"].play();
        }, 200);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/components/header.css"></style>
