import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  { // 홈
    path: '/',
    name: 'Home',
    component: Home
  },

  { // Work
    path: '/work',
    name: 'Work',
    component: () => import(/* webpackChunkName: "Work" */ '../views/Work.vue')
  },

  { // Culture
    path: '/culture',
    name: 'Culture',
    component: () => import(/* webpackChunkName: "Culture" */ '../views/Culture.vue')
  },

  { // Contact
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  { // Contact - Detail
    path: '/contact/detail',
    name: 'ContactDetail',
    component: () => import(/* webpackChunkName: "ContactDetail" */ '../views/ContactDetail.vue'),
    props : route => ({ which: route.query.which ? route.query.which : '견적'})
  },
  { // CI
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
  },

  { // 404 페이지
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  { // 404로 리다이렉트
      path: '/:pathMatch(.*)*',
      redirect: "/404"
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      window.scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    }
  },
})

export default router
