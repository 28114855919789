
function init(el){
    el.classList.remove('circleMotion_on')
}

const circleMotion = {
    beforeMount(el) {
        init(el)
    },
    mounted(el, binding) {
        function motion() {
            el.classList.add('circleMotion_on')
        }

        function createObserver() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) { // 감지대상이 교차영역에 진입 할 경우
                        motion();
                        if(binding.arg != 'reverse'){
                            observer.unobserve(el); // reverse 아니면 관찰할 필요 x
                        }
                    } else {
                        if(binding.arg == 'reverse'){
                            init(el);
                        }
                    }
                });
            }, {
                rootMargin: '0% 0px 0%',
                threshold: 0.4,
            });

            observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 모션을 시켜도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : motion();
    },
}

export default circleMotion;